export const BASE_URL = "https://script.merchhq.io/";
// export const BASE_URL = "http://192.168.1.23/merchhq/public/";// pushpa
// export const BASE_URL = "http://192.168.1.23/new_laravel_mhq/public/"; // local mukesh

export const URL = {
  REGISTER: "vendor/vendor-store",
  LOGIN: "vendor/vendor-user-login",
  VENDOR_CATEGORY: "vendor/parent-categories",
  REGISTER_VIEW: "vendor/vendor-view/",
  VERIFICATION: "vendor/verification/",
  PROFILE_LIST: "vendor/vendor-user-list/",
  USER_LIST: "vendor/vendor-user-list",
  STATUS_LIST: "vendor/vendor-user-status/",
  ADD_PROFILE: "vendor/vendor-user-store",
  ROLE_LIST: "vendor/admin/role/active-list",
  ROLE_ACTIVE_LIST: "/vendor/admin/role/lists",
  EDIT_PROFILE: "vendor/vendor-user-edit",
  PROFILE_VIEW: "vendor/vendor-user-view/",
  ROLE_VIEW: "vendor/role/",
  ROLE_CREATE: "vendor/role/create",
  VENDOR_PARENT_CATEGORIES: "vendor/parent-categories",
  FORGOT_PASSWORD: "vendor/forgot-password",
  RESET_PASSWORD: "vendor/reset-password",
  CURRENCY: "vendor/active/currency",
  VENDOR_VIEW: "vendor/vendor-view/",
  VENDOR_VIEW_NEW: "vendor/vendor/profile/",
  VENDOR_EDIT: "vendor/vendor-edit",
  VENDOR_DEPARTMENT: "vendor/vendor-department-list",
  TAGS_LIST: "vendor/tag-list",
  ADDRESS_LIST: "vendor/vendor-address-list/",
  ADDRESS_ADD: "vendor/vendor-address-store",
  ADDRESS_EDIT: "vendor/vendor-address-edit",
  ADDRESS_STATUS: "vendor/vendor-address-status/",
  VENDOR_POSITION_CHANGE: "vendor/vendor-position-change",
  //PRODUCTSUGGESTION

  PRODUCT_SUGGESTION_LIST: "vendor/product-suggest-list",
  //  PRODUCT_SUGGESTION_STATUS: "product-suggest-status/",
  PRODUCT_SUGGESTION_ADD: "vendor/product-suggest-store",
  PRODUCT_SUGGESTION_VIEW: "vendor/product-suggestion-show/",
  PRODUCT_SUGGESTION_EDIT: "vendor/product-suggest-edit",

  //CATEGORY Id

  SUB_CATEGORY: "vendor/active-child-category-products/",

  VENDOR_LIBRARY: "vendor/vendor-library-store",
  VENDOR_LIBRARY_LIST: "vendor/vendor-library-list",
  VENDOR_PROFILE_CONTACT: "vendor/vendors-users-list",
  VENDOR_PROFILE_REMIND: "vendor/admin-list",
  VENDOR_THREAD_LIST: "vendor/thread-list/",
  VENDOR_LIBRARY_EDIT: "vendor/vendor-library-edit",
  VENDOR_LOGO_DELETE: "vendor/vendor-logo-delete",

  // VENDOR MAP PRODUCTS
  VENDOR_MAP_PRODUCT_LIST: "vendor/map-product/lists",
  VENDOR_CATEGORY_LIST: "vendor/category-list/",
  MAP_PRODUCTS_VIEW: "vendor/products/",
  MAP_PRODUCTS_PRICE: "vendor/map-product-price",
  MAP_ACCEPTED_PRODUCTS: "vendor/accept-map-product/lists",
  MAP_PRODUCT_VIEWS: "vendor/view-product",
  PRODUCTS_BULK_STATUS_CHANGE: "vendor/change-approved-request-status",

  // VENDOR SCHEDULE
  VENDOR_SCHEDULE_STORE: "vendor/vendor-schedule-store",
  VENDOR_SCHEDULE_LIST: "vendor/vendor-schedule-list",
  VENDOR_SCHEDULE_THREADS: "vendor/thread-list/",

  //DECORATIVE LIST
  DECORATIVE_DROPDOWN_LIST: "vendor/admin/decorative-method/list",
  HSN_LIST: "vendor/hsn",
  COLOR_LIST: "vendor/colors",
  ATTRIBUTES_LIST: "vendor/attribute",
  PRODUCT_REQUEST_STORE: "vendor/store-product-request",
  PRODUCT_REQUEST_LIST: "vendor/product-request-list",
  PRODUCT_REQUEST_VIEW: "vendor/product-request-show/",
  PRODUCT_REQUEST_EDIT: "vendor/product-request-edit",

  PRODUCT_APPROVED_EDIT: `vendor/vendor-accept-product/`,
  //PRICE CHANGE
  PRICE_CHANGE_LIST: "/vendor/vendor-request-price-change-products/lists",
  PRODUCT_VENDOR_PRICE_REQUEST_VIEW: "/vendor/view-vendor-price-request/",

  //DECORATIVE METHOD
  DECORATIVE_ADDED: "vendor/decor-price-store",
  DECORATIVE_LIST: "vendor/list-vendor-decor-price",
  DECORATIVE_STATUS: "vendor/decorative-method-status/",
  DECORATIVE_VIEW: "vendor/vendor-decor-price-show/",
  //DROUPDOWN
  DECORATIVE_DROPDOWN: "vendor/decorative-method",
};

export const TOKEN = "token";
export const USER_DETAILS = "user_details";
export const PROFILE = "profile";

export const NUMBER = /^\+?(0|[6-9]\d*)$/;
export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const productDetailImg = {
  loop: true,
  autoplay: false,
  center: true,
  dots: true,
  autoplayTimeout: 3000,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
  },
};
